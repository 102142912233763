import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getLanguages,
  getChannelsTypes,
  updateOrganization,
  getBusinessUnits,
  getCurrencies,
  deleteBusinessUnit,
  createBusinessUnit,
  editBusinessUnit,
  fetchOrganizationById,
  getChannels,
  createChannel,
} from "../../api/Services";
import { Organization } from "../../models/Models";

import {
  Button,
  Form,
  Input,
  Spin,
  message,
  Table,
  Modal,
  Select,
  Tag,
  Dropdown,
  Menu,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import styles from "./BusinessRules.module.scss";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

const BusinessRules = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [data, setData] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [languages, setLanguages] = useState<any[]>([]);
  const [channelTypes, setChannelTypes] = useState<any[]>([]);
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [channels, setChannels] = useState<any[]>([]);
  const [totalSizeChannels, setTotalSizeChannels] = useState(0);
  const [actualPageChannels, setActualPageChannels] = useState(1);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [businessUnits, setBusinessUnits] = useState<any[]>([]);

  const [form] = Form.useForm();
  const [channelForm] = Form.useForm();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const canEdit = hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS);

  useEffect(() => {
    if (hasPermission(privilegesEnum.MODIFY_ORGANIZATION))
      handleFetchOrganization();

    if (hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS)) {
      handleFetchLanguages();
    }
    if (hasPermission(privilegesEnum.READ_BUSINESS_UNITS)) {
      handleFetchChannels();
      handleFetchChannelTypes();
      handleFetchCurrencies();
      handleFetchBusinessUnits();
    }
  }, []);

  const channelOptions = useMemo(() => {
    const selectedBu = businessUnits.find(
      (bu) => bu.id === businessUnitSelected
    );

    if (!selectedBu) return;

    const options: any[] = [];
    channelTypes.forEach(function (item: any) {
      let disabled = false;
      const existChannel = selectedBu?.channels?.find(
        (c: { type: { id: any } }) => c.type.id === item.id
      );

      if (existChannel) {
        disabled = true;
      }
      options.push(
        <Select.Option value={item.id} key={item.id} disabled={disabled}>
          {item.descriptionEs}
        </Select.Option>
      );
    });
    return options;
  }, [businessUnitSelected, businessUnits]);

  const handleFetchChannels = async (pagination = actualPageChannels) => {
    setLoadingChannels(true);
    const response = await getChannels(pagination - 1);
    const allChannels = response.channels;
    setChannels(allChannels);
    setTotalSizeChannels(response.totalElements);
    setLoadingChannels(false);
  };

  const handleFetchOrganization = async () => {
    setLoading(true);

    let currentOrganization = await fetchOrganizationById();

    setOrganization(currentOrganization);

    setLoading(false);
  };

  const onFinish = async () => {
    setLoading(true);

    const organizationId = (await localStorage.getItem("organizationId")) ?? "";

    const realName = form.getFieldValue("realName");
    const businessName = form.getFieldValue("businessName");
    const rut = form.getFieldValue("rut");
    const address = form.getFieldValue("address");

    let response = await updateOrganization(
      organizationId,
      realName,
      businessName,
      rut,
      address
    );
    if (response) message.success(t("BusinessRules-UpdateSuccessMessage"));
    else message.error(t("BusinessRules-UpdateErrorMessage"));
    setLoading(false);
  };

  const handleCancelButton = () => {
    form.resetFields();
  };

  const handleFetchLanguages: () => any = async () => {
    const languages = await getLanguages();
    const children: any[] = [];

    languages.forEach(function (item: any) {
      children.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    setLanguages(children);
  };

  const handleFetchChannelTypes: () => any = async () => {
    const types = await getChannelsTypes();
    const children: any[] = [];
    types.forEach(function (item: any) {
      children.push(item);
    });
    setChannelTypes(children);
  };

  const handleFetchCurrencies: () => any = async () => {
    const currencies = await getCurrencies();
    const children: any[] = [];
    currencies.forEach(function (item: any) {
      children.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    setCurrencies(children);
  };

  const handleCreateBusinessUnit = async (data: any) => {
    setLoading(true);

    const name = data.name;
    const languageId = data.language;
    const preferenceCurrency = data.currency;

    let response = await createBusinessUnit(
      name,
      languageId,
      preferenceCurrency
    );

    if (response) {
      message.success(t("BusinessRules-CreateBusinessUnitSucessMessage"));
      handleFetchBusinessUnits();
    } else message.error(t("Common-ErrorMessage"));

    Modal.destroyAll();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchBusinessUnits(page);
    setActualPage(page);
  };

  const handlePaginationChannels = (page: number) => {
    handleFetchChannels(page);
    setActualPageChannels(page);
  };

  const handleFetchBusinessUnits = async (pagination = actualPage) => {
    const response = await getBusinessUnits(pagination);
    const allUnits = response.businessUnits;

    setBusinessUnits(allUnits);
    setData(allUnits);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showNewUnitModal = () => {
    Modal.info({
      title: t("BusinessRules-NewBusinessUnit"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      icon: null,
      content: (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleCreateBusinessUnit}
          preserve={false}
        >
          <Form.Item
            className={styles.newUnitItem}
            label={t("Common-Name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("Common-NameNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>

          <Form.Item
            className={styles.newUnitItem}
            label={t("BusinessRules-Language")}
            name="language"
          >
            <Select
              className={styles.language}
              allowClear
              style={{ width: "100%" }}
              placeholder=""
            >
              {languages}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.newUnitItem}
            label={t("BusinessRules-PreferenceCurrency")}
            name="currency"
          >
            <Select
              className={styles.type}
              allowClear
              style={{ width: "100%" }}
              placeholder=""
            >
              {currencies}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.addNewUnitButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Add")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const showDeleteBusinessUnitConfirm = (channelId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteBusinessUnit(channelId);
      },
    });
  };
  const handleDeleteBusinessUnit = async (channelId: number) => {
    setLoading(true);
    const deleteStatus = await deleteBusinessUnit(channelId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchBusinessUnits();

    setLoading(false);
  };

  const handleEditBusinessUnit = async (data: any) => {
    setLoading(true);

    const id = data.businessUnitId;
    const { name, language, currency, targetMargin } = data;

    let response = await editBusinessUnit(id, {
      name,
      languageId: language,
      currencyId: currency,
      targetMargin,
    });

    if (response) {
      message.success(t("Roles-EditSuccesMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchBusinessUnits();
    setLoading(false);
  };

  const showEditBusinessUnit = (
    businessUnitId: number,
    name: string,
    language: string | undefined,
    currency: any,
    targetMargin: number
  ) => {
    Modal.info({
      title: t("BusinessRules-EditBusinessUnit"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      icon: null,
      content: (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleEditBusinessUnit}
          preserve={false}
        >
          <Form.Item
            hidden
            initialValue={businessUnitId}
            name="businessUnitId"
          />

          <Form.Item hidden initialValue={targetMargin} name="targetMargin" />

          <Form.Item
            label={t("Common-Name")}
            name="name"
            initialValue={name}
            className={styles.newChannelItem}
            rules={[
              {
                required: true,
                message: t("Common-NameNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>

          <Form.Item
            label={t("BusinessRules-Language")}
            name="language"
            initialValue={language}
            className={styles.newChannelItem}
          >
            <Select
              className={styles.language}
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={language}
            >
              {languages}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("BusinessRules-PreferenceCurrency")}
            name="currency"
            initialValue={currency}
            className={styles.newChannelItem}
          >
            <Select
              className={styles.type}
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={currency}
            >
              {currencies}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.editUnitButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("BusinessRules-Language"),
      dataIndex: "language",
      key: "language",
      render: (language: any) => language?.name,
    },
    {
      title: t("BusinessRules-Currency"),
      dataIndex: "currency",
      key: "currency",
      render: (currency: any) => currency?.name,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    showEditBusinessUnit(
                      record.id,
                      record.name,
                      record.language?.id,
                      record.currency?.id,
                      record.targetMargin
                    );
                  }}
                  icon={<EditOutlined />}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              )}
              {hasPermission(privilegesEnum.DELETE_BUSINESS_UNITS) && (
                <Menu.Item
                  key="delete"
                  onClick={() => showDeleteBusinessUnitConfirm(record.id)}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];
  

  const handleCreateChannel = async (data: any) => {
    setLoadingChannels(true);

    const currenciesIds = data.currency;
    const channelTypeId = data.type;

    if (businessUnitSelected) {
      let response = await createChannel(
        parseInt(businessUnitSelected),
        currenciesIds,
        channelTypeId
      );

      if (response) {
        message.success(t("BusinessRules-CreateChannelSucessMessage"));
        await handleFetchChannels();
        channelForm.resetFields();
        setIsCreateModalVisible(false);
      } else message.error(t("Common-ErrorMessage"));
      setLoadingChannels(false);
    }
  };

  const channelColumns = [
    {
      title: t("Common-Type"),
      dataIndex: "type",
      key: "type",
      render: (channel: any) => channel.descriptionEs,
    },
    {
      title: t("Common-Currencies"),
      dataIndex: "currencies",
      key: "currencies",
      render: (currencies: any[]) => (
        <>
          {currencies.map((currency) => {
            return (
              <Tag className={styles.channelTag} color="blue" key={currency.id}>
                {currency.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-BusinessUnit"),
      key: "businessUnit",
      render: (channel: any) => channel.businessUnit.name,
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}> {t("BusinessRules-Title")}</h1>
        {hasPermission(privilegesEnum.MODIFY_ORGANIZATION) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={onFinish}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      {hasPermission(privilegesEnum.MODIFY_ORGANIZATION) && (
        <div className={styles.companyInfoWrapper}>
          <h1 className={styles.companyInfoTitle}>
            {t("BusinessRules-CompanyInfoTitle") + organization?.name}
          </h1>
          {loading && organization ? (
            <div className={styles.loadingWrapper}>
              <Spin
                indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
                delay={500}
              />
            </div>
          ) : (
            <Form
              name="businessRules"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
              className={styles.companyForm}
              autoComplete="off"
            >
              <Form.Item
                label={t("Common-Name")}
                name="realName"
                initialValue={organization?.realName}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("BusinessRules-BusinessName")}
                name="businessName"
                initialValue={organization?.businessName}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("BusinessRules-Rut")}
                name="rut"
                initialValue={organization?.rut}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("BusinessRules-Address")}
                name="address"
                initialValue={organization?.address}
              >
                <Input />
              </Form.Item>
            </Form>
          )}
        </div>
      )}
      {hasPermission(privilegesEnum.READ_BUSINESS_UNITS) && (
        <div className={styles.businessUnitWrapper}>
          <div className={styles.businessUnitHeader}>
            <h1 className={styles.companyInfoTitle}>
              {t("BusinessRules-BusinessUnits")}
            </h1>
            {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
              <Button
                onClick={showNewUnitModal}
                className={styles.newUnitButton}
                type="primary"
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <Table
            size="small"
            className={styles.tableBusinessUnits}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      )}

      <div className={styles.channelWrapper}>
        <div className={styles.tablesHeader}>
          <h1 className={styles.tableTitle}>{t("Common-Channels")}</h1>
          {canEdit && (
            <Button
              type="primary"
              className={styles.actionsButton}
              onClick={() => setIsCreateModalVisible(true)}
              icon={<PlusOutlined />}
            />
          )}
        </div>
        <div>
          <Table
            size="small"
            className={styles.table}
            loading={loadingChannels}
            columns={channelColumns}
            dataSource={channels}
            pagination={{
              total: totalSizeChannels,
              showSizeChanger: false,
              onChange: handlePaginationChannels,
            }}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
      <Modal
        title={t("BusinessRules-NewChannel")}
        visible={isCreateModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <Form
          name="createChannel"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleCreateChannel}
          form={channelForm}
          preserve={false}
          layout="vertical"
        >
          <Form.Item
            label={t("BusinessRules-ChannelType")}
            name="type"
            rules={[
              {
                required: true,
                message: t("BusinessRules-ChannelTypeNotEntered"),
              },
            ]}
          >
            <Select
              className={styles.type}
              allowClear
              style={{ width: "100%" }}
              placeholder={t("BusinessRules-ChannelTypeNotEntered")}
              disabled={businessUnitSelected == null}
            >
              {channelOptions}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("BusinessRules-Currency")}
            name="currency"
            rules={[
              {
                required: true,
                message: t("BusinessRules-CurrencyNotEntered"),
              },
            ]}
          >
            <Select
              className={styles.type}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder={t("BusinessRules-Currency")}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {currencies}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.addNewButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loadingChannels ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Add")
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BusinessRules;
