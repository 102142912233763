import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getTickets,
  deleteTicket,
  getBranchOfficesFiltered,
  getSkusFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Button,
  message,
  Space,
  Modal,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import styles from "./Tickets.module.scss";

const Tickets = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [codeFilterValue, setCodeFilterValue] = useState("");
  const [totalLessFilterValue, setTotalLessFilterValue] = useState<
    number | undefined
  >(undefined);
  const [totalGreaterFilterValue, setTotalGreaterFilterValue] = useState<
    number | undefined
  >(undefined);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [branchOfficeFilterValue, setBranchOfficeFilterValue] =
    useState<number>();
  const [actualFilterStartDate, setActualFilterStartDate] = useState<any>(null);
  const [actualFilterEndDate, setActualFilterEndDate] = useState<any>(null);
  const [skuOptions, setSkuOptions] = useState<any[]>([]);
  const [skusSelected, setSkusSelected] = useState([]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_TICKETS)) {
      handleFetchTickets();
    }
  }, [
    codeFilterValue,
    branchOfficeFilterValue,
    actualFilterStartDate,
    actualFilterEndDate,
    skusSelected,
    totalLessFilterValue,
    totalGreaterFilterValue,
  ]);

  useEffect(() => {
    handleSearchBranchOffices("");
  }, []);

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, {
      name: value,
    });
    setBranchOffices(response.branchOffices);
  };

  const handleFetchTickets = async (pagination = actualPage) => {
    const response = await getTickets(pagination - 1, {
      code: codeFilterValue,
      branchOfficeId: branchOfficeFilterValue,
      skuIds: skusSelected,
      minTotal: totalLessFilterValue,
      maxTotal: totalGreaterFilterValue,
      startDate: actualFilterStartDate
        ? moment(actualFilterStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : undefined,
      endDate: actualFilterEndDate
        ? moment(actualFilterEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : undefined,
    });
    setData(response.tickets);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handleSearchSkus = async (value: string) => {
    const response = await getSkusFiltered(0, { skuName: value });
    setSkuOptions(response.skus);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };

  const handleDelete = async (ticketId: number) => {
    setLoading(true);
    const deleteStatus = await deleteTicket(ticketId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchTickets();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchTickets(page);
    setActualPage(page);
  };
  const handleChangeDateFilter = (value: any) => {
    setActualFilterStartDate(value ? value[0] ?? null : null);
    setActualFilterEndDate(value ? value[1] ?? null : null);
  };
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const columns = [
    {
      title: t("Common-Date"),
      dataIndex: "date",
      key: "date",
      render: (date: any) => moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
    {
      title: t("Common-Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Common-Type"),
      render: (ticket: any) => ticket.documentType.description_es,
      key: "documentType",
    },
    {
      title: t("Common-BranchOffice"),
      render: (ticket: any) => (
        <a
          href={`/admin/branchoffices/${ticket.salePoint.branchOffice?.id}/view`}
        >
          {ticket.salePoint.branchOffice?.name}
        </a>
      ),
      key: "branchOffice",
    },
    {
      title: t("Common-Client"),
      dataIndex: "fidelity",
      key: "fidelity",
    },
    {
      title: t("Common-Total"),
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
  overlay={
    <Menu>
      <Menu.Item
        key="edit"
        icon={
          hasPermission(privilegesEnum.WRITE_TICKETS) ? (
            <EditOutlined />
          ) : (
            <EyeOutlined />
          )
        }
        onClick={() => navigate(`/promotions/tickets/${record.id}/view`)}
        >
        {t(
          hasPermission(privilegesEnum.WRITE_TICKETS)
            ? "Common-Edit"
            : "Common-View"
        )}
      </Menu.Item>
      {hasPermission(privilegesEnum.DELETE_TICKETS) && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(record.id)}
        >
          {t("Common-Delete")}
        </Menu.Item>
      )}
    </Menu>
  }
>
  <Button className={styles.actionButtonMore}>
    <MoreOutlined className={styles.actionIconMore} />
  </Button>
</Dropdown>

      ),
    },
  ];
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Tickets")}</h1>
        {hasPermission(privilegesEnum.WRITE_TICKETS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button className={styles.importButton} type="primary">
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_TICKETS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByCode")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setCodeFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={codeFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    className={styles.filterSelect}
                    showSearch
                    onSearch={handleSearchBranchOffices}
                    placeholder={t("Common-SearchByBranchOffice")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) setBranchOfficeFilterValue(option.value);
                    }}
                    onClear={() => setBranchOfficeFilterValue(undefined)}
                  >
                    {getOptions(branchOffices)}
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelector}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    onChange={handleChangeDateFilter}
                    onCalendarChange={handleChangeDateFilter}
                    placeholder={[t("Common-Start"), t("Common-End")]}
                  />
                </div>
              </div>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <InputNumber
                    placeholder={t("Common-TotalFrom")}
                    className={styles.filterInput}
                    onChange={(value) => {
                      setTotalLessFilterValue(value ?? undefined);
                      setActualPage(1);
                    }}
                    value={totalLessFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <InputNumber
                    placeholder={t("Common-TotalTo")}
                    className={styles.filterInput}
                    onChange={(value) => {
                      setTotalGreaterFilterValue(value ?? undefined);
                      setActualPage(1);
                    }}
                    value={totalGreaterFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    onClear={() => {
                      setSkusSelected([]);
                    }}
                    placeholder={t("Common-SearchSkuPlaceholder")}
                    onChange={(value) => {
                      setSkusSelected(value as []);
                    }}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchSkus}
                    options={(skuOptions || []).map((d: any) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tickets;
