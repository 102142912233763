import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteSupplier,
  processExcelTemplate,
  getSuppliersFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import { Table, message, Modal, Button, Input, Menu, Dropdown } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Suppliers.module.scss";
import { useNavigate } from "react-router-dom";
import SupplierPaymentMethods from "../supplierpaymentmethods/SupplierPaymentMethods";

const Suppliers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");

  const canEdit = hasPermission(privilegesEnum.WRITE_SUPPLIERS);
  const canDelete = hasPermission(privilegesEnum.DELETE_SUPPLIERS);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_SUPPLIERS)) {
      handleFetchSuppliers(1);
      setActualPage(1);
    }
  }, [businessUnitSelected, nameFilterValue, externalIdFilterValue]);

  const handleFetchSuppliers = async (pagination = actualPage) => {
    if (businessUnitSelected) {
      const response = await getSuppliersFiltered(pagination - 1, {
        businessUnitId: businessUnitSelected,
        name: nameFilterValue,
        externalId: externalIdFilterValue,
      });
      const allSuppliers = response.suppliers;

      setSuppliers(allSuppliers);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("suppliers", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchSuppliers();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const handlePagination = (page: number) => {
    handleFetchSuppliers(page);
    setActualPage(page);
  };

  const showDeleteConfirm = (supplierId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSupplier(supplierId);
      },
    });
  };

  const handleDeleteSupplier = async (supplierId: number) => {
    setLoading(true);
    const deleteStatus = await deleteSupplier(supplierId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchSuppliers();
    setLoading(false);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Website"),
      dataIndex: "website",
      key: "website",
      render: (website: any) => <a href={website}>{website}</a>,
    },
    {
      title: t("Suppliers-CatalogueLink"),
      dataIndex: "catalogueLink",
      key: "catalogueLink",
      render: (catalogueLink: any) => (
        <a href={catalogueLink}>{catalogueLink}</a>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
  overlay={
    <Menu>
      <Menu.Item
        key="edit"
        icon={
          hasPermission(privilegesEnum.WRITE_SUPPLIERS) ? (
            <EditOutlined />
          ) : (
            <EyeOutlined />
          )
        }
        onClick={() => navigate(`/admin/suppliers/${record.id}/view`)}
      >
        {t(
          hasPermission(privilegesEnum.WRITE_SUPPLIERS)
            ? "Common-Edit"
            : "Common-View"
        )}
      </Menu.Item>
      {hasPermission(privilegesEnum.DELETE_SUPPLIERS) && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(record.id)}
        >
          {t("Common-Delete")}
        </Menu.Item>
      )}
    </Menu>
  }
>
  <Button className={styles.actionButtonMore}>
    <MoreOutlined className={styles.actionIconMore} />
  </Button>
</Dropdown>
      ),
    },
  ];
  

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1 className={styles.title}>{t("Suppliers-Suppliers")}</h1>
          {hasPermission(privilegesEnum.WRITE_SUPPLIERS) && (
            <div className={styles.actionButtonsWrapper}>
              <Button
                href="/admin/suppliers/create"
                className={styles.newSupplierButton}
                type="primary"
                icon={<PlusOutlined />}
              />
              <Button
                onClick={() => {
                  setImportErrors("");
                  setIsImportModalVisible(true);
                }}
                className={styles.importButton}
                type="primary"
              >
                {t("Common-Import")}
              </Button>
              <Button
                onClick={() =>
                  handleDownloadTemplate("suppliers", setDownloadingTemplate)
                }
                loading={downloadingTemplate}
                className={styles.importButton}
                type="primary"
              >
                {t("Common-DownloadTemplate")}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.contentWrapper}>
          {hasPermission(privilegesEnum.READ_SUPPLIERS) && (
            <div className={styles.tableSuppliersWrapper}>
              <div className={styles.headerTableWrapper}>
                <div className={styles.filtersWrapper}>
                  <div className={styles.filterWrapper}>
                    <Input
                      placeholder={t("Common-SearchByName")}
                      className={styles.filterInput}
                      onChange={(e) => setNameFilterValue(e.target.value)}
                      value={nameFilterValue}
                    />
                  </div>
                  <div className={styles.filterWrapper}>
                    <Input
                      placeholder={t("Common-SearchByExternalId")}
                      className={styles.filterInput}
                      onChange={(e) => setExternalIdFilterValue(e.target.value)}
                      value={externalIdFilterValue}
                    />
                  </div>
                </div>
              </div>

              <Table
                size="small"
                className={styles.tableSuppliers}
                loading={loading}
                columns={columns}
                dataSource={suppliers}
                rowKey={(record) => record.id}
                pagination={{
                  total: totalSize,
                  showSizeChanger: false,
                  onChange: handlePagination,
                  current: actualPage,
                }}
              />
            </div>
          )}
        </div>
        <Modal
          title={t("Common-ImportEntity", { entity: t("Common-Suppliers") })}
          visible={isImportModalVisible}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setIsImportModalVisible(false)}
        >
          <ImportModal onOk={handleImportFile} errors={importErrors} />
        </Modal>
      </div>
      <SupplierPaymentMethods />
    </>
  );
};

export default Suppliers;
