import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createRol,
  deleteRol,
  editRol,
  getRoles,
  getPrivileges,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Input,
  Button,
  message,
  Select,
  Tag,
  Space,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import styles from "./Roles.module.scss";

const Roles = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [privileges, setPrivileges] = useState<any[]>([]);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [privilegeFilterValue, setPrivilegeFilterValue] = useState<number>();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ROLES)) handleFetchRoles();
  }, [nameFilterValue, privilegeFilterValue]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_ROLES)) handleFetchPrivileges();
  }, []);

  function showEditModal(rolId: number) {
    let rol = { name: "", description: "", privileges: [] };
    const editedPrivileges: number[] = [];
    data?.forEach(function (item: any) {
      if (item.id === rolId) {
        rol = item;
        item.privileges.forEach(function (privilege: any) {
          editedPrivileges.push(privilege.id);
        });
      }
    });

    Modal.info({
      title: t("Roles-EditRol"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      onOk: handleEditRol,
      content: (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={editForm}
          autoComplete="off"
          className={styles.formEditRol}
          preserve={false}
        >
          <Form.Item hidden initialValue={rolId} name="id" />
          <Form.Item
            initialValue={rol?.name}
            label={t("Common-Name")}
            name="name"
            className={styles.editItem}
            rules={[
              {
                required: true,
                message: t("Common-NameNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>
          <Form.Item
            initialValue={rol?.description}
            label={t("Roles-Description")}
            name="description"
            className={styles.editItem}
          >
            <Input placeholder={t("Roles-Description")} />
          </Form.Item>
          <Form.Item
            initialValue={editedPrivileges}
            label={t("Roles-Privileges")}
            name="privileges"
            className={styles.editItem}
          >
            <Select
              className={styles.privileges}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={editedPrivileges}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {privileges}
            </Select>
          </Form.Item>
        </Form>
      ),
    });
  }

  const handleFetchPrivileges: () => any = async () => {
    const privileges = await getPrivileges();
    const children: any[] = [];

    privileges?.forEach(function (item: any) {
      children.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });
    setPrivileges(children);
  };

  const handleFetchRoles = async (pagination = actualPage) => {
    const response = await getRoles(pagination, {
      name: nameFilterValue,
      privilegeId: privilegeFilterValue,
    });

    setData(response.roles);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (rolId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteRol(rolId);
      },
    });
  };

  const handleDeleteRol = async (rolId: number) => {
    setLoading(true);
    const deleteStatus = await deleteRol(rolId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Roles-DeleteRolSuccessMessage"));
    await handleFetchRoles();
    setLoading(false);
  };

  const handleCreateRol = async (data: any) => {
    setLoading(true);
    let response = await createRol(
      data.name,
      data.description ?? "",
      data.privileges
    );
    if (response) {
      message.success(t("Roles-CreateSuccesMessage"));
      form.resetFields();
      await handleFetchRoles(actualPage);
    } else message.error(t("Roles-CreateErrorMessage"));

    setLoading(false);
  };

  const handleEditRol = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const name = editForm.getFieldValue("name");
    const description = editForm.getFieldValue("description");
    const privileges = editForm.getFieldValue("privileges");

    let response = await editRol(id, name, description, privileges);

    if (response) {
      message.success(t("Roles-EditSuccesMessage"));
    } else message.error(t("Roles-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchRoles();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchRoles(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Roles-Rol"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Roles-Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Roles-Privileges"),
      dataIndex: "privileges",
      key: "privileges",
      render: (privileges: any[]) => (
        <>
          {privileges.map((privilege) => (
            <Tag
              className={styles.privilegeTag}
              color="blue"
              key={privilege.name}
            >
              {privilege.descriptionEs}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) =>
        record.key != 1 && (
          <Dropdown
            overlay={
              <Menu>
                {hasPermission(privilegesEnum.WRITE_ROLES) && (
                  <Menu.Item
                    key="edit"
                    icon={<EditOutlined />}
                    onClick={() => showEditModal(record.key)}
                  >
                    {t("Common-Edit")}
                  </Menu.Item>
                )}
                {hasPermission(privilegesEnum.DELETE_ROLES) && (
                  <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={() => showDeleteConfirm(record.key)}
                  >
                    {t("Common-Delete")}
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button className={styles.actionButtonMore}>
              <MoreOutlined className={styles.actionIconMore} />
            </Button>
          </Dropdown>
        ),
    },
  ];
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Roles-RolesSettings")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableRolesWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => setNameFilterValue(e.target.value)}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  className={styles.filterSelect}
                  placeholder={t("Common-SearchByPrivilege")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) setPrivilegeFilterValue(option.value);
                  }}
                  onClear={() => setPrivilegeFilterValue(undefined)}
                >
                  {privileges}
                </Select>
              </div>
            </div>
          </div>
          <Table
            size="small"
            className={styles.tableRoles}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
        {hasPermission(privilegesEnum.WRITE_ROLES) && (
          <div className={styles.newRolWrapper}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={handleCreateRol}
              form={form}
              autoComplete="off"
              className={styles.formNewRol}
            >
              <h1 className={styles.formTitle}>{t("Roles-NewRol")}</h1>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Name")} />
              </Form.Item>
              <Form.Item label={t("Roles-Description")} name="description">
                <Input placeholder={t("Roles-Description")} />
              </Form.Item>
              <Form.Item
                label={t("Roles-Privileges")}
                name="privileges"
                rules={[
                  {
                    required: true,
                    message: t("Roles-PrivilegesNotEntered"),
                  },
                ]}
              >
                <Select
                  className={styles.privileges}
                  mode="multiple"
                  allowClear
                  placeholder={t("Roles-SelectPrivileges")}
                  // onChange={handleChange}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {privileges}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Common-Add")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Roles;
