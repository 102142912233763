import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  deleteRepositionOrder,
  editRepositionOrderStatus,
  getBranchOfficesFiltered,
  getRepositionOrdersByOrganization,
} from "../../../api/Services";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

import {
  Table,
  Select,
  Spin,
  Dropdown,
  Menu,
  Button,
  Modal,
  Form,
  message,
} from "antd";

import styles from "./Requests.module.scss";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
  MoreOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { purchaseOrderStatus } from "../../../helpers/Constants";

const Requests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [actualBranchOfficeId, setActualBranchOfficeId] = useState<any>(null);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [statusForm] = Form.useForm();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleChangeStatus = async () => {
    const { id, status } = statusForm.getFieldsValue();
    const response = await editRepositionOrderStatus(id, status);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      handleFetchData(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const handleDeleteRepositionOrder = async (repositionOrderId: number) => {
    setLoading(true);
    const deleteStatus = await deleteRepositionOrder(repositionOrderId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchData();
    setLoading(false);
  };

  const showDeleteConfirm = (repositionOrderId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteRepositionOrder(repositionOrderId);
      },
    });
  };

  const showEditStatusModal = (order: any) => {
    Modal.info({
      title: t("Common-Status"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="editOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={statusForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={order.id} name="id" />
          <Form.Item
            label={t("Common-Status")}
            name="status"
            initialValue={order.orderStatus.status}
            rules={[
              {
                required: true,
                message: t("Common-Status"),
              },
            ]}
          >
            <Select
              className={styles.filterSelect}
              allowClear
              defaultValue={order.orderStatus.status}
            >
              <Select.Option
                value={purchaseOrderStatus.SUGGESTED}
                key={purchaseOrderStatus.SUGGESTED}
              >
                {t("Common-Suggested")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.GENERATED}
                key={purchaseOrderStatus.GENERATED}
              >
                {t("Common-Generated")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.SENT}
                key={purchaseOrderStatus.SENT}
              >
                {t("Common-Sent")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.IN_PROGRESS}
                key={purchaseOrderStatus.IN_PROGRESS}
              >
                {t("Common-InProgress")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.COMPLETED}
                key={purchaseOrderStatus.COMPLETED}
              >
                {t("Common-Completed")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleChangeStatus}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const handlePagination = (page: number) => {
    handleFetchData(page);
    setActualPage(page);
  };

  const handleFetchData = async (
    pagination = actualPage,
    branchOfficeFromId?: number
  ) => {
    setLoadingArticles(true);

    const response = await getRepositionOrdersByOrganization({
      pagination: pagination - 1,
      branchOfficeFrom: branchOfficeFromId ?? actualBranchOfficeId ?? undefined,
    });

    setData(response.repositionOrders);
    setTotalSize(response.totalElements);
    setLoadingArticles(false);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1 && businessUnitSelected) {
      setLoadingBranchOffices(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setLoadingBranchOffices(false);
    } else setBranchOfficeOptions([]);
  };

  const getRowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? styles.rowLight : styles.rowDark;
  };

  const columns = [
    {
      title: t("Common-Order"),
      key: "order",
      render: (order: any) => (
        <a href={`/repositionorders/requests/${order.id}/detail`}>{order.id}</a>
      ),
    },
    {
      title: t("Common-RequestDate"),
      dataIndex: "createdDate",
    },
    {
      title: t("Common-QuantityArticles"),
      key: "quantity",
      render: (order: any) => {
        return order.skus.reduce((accumulator: number, sku: any) => {
          return accumulator + sku.quantity;
        }, 0);
      },
    },
    {
      title: t("Common-Destiny"),
      key: "destiny",
      render: (order: any) => {
        const isDeposit = order.depositTo !== null;
        const id = isDeposit ? order.depositTo.id : order.branchOfficeTo.id;
        const name = isDeposit
          ? order.depositTo.name
          : order.branchOfficeTo.name;

        return (
          <a
            className={styles.locationWrapper}
            href={`/${
              isDeposit ? "admin/deposits" : "admin/branchoffices"
            }/${id}/view`}
          >
            <span>{name}</span>
            <span>{id}</span>
          </a>
        );
      },
    },
    {
      title: t("Common-Priority"),
      key: "priority",
    },
    {
      title: t("Common-Status"),
      key: "status",
      render: (order: any) => (
        <span className={styles.statusWrapper}>
          {order.orderStatus?.description_es}
        </span>
      ),
    },
    {
      key: "actions",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/repositionorders/${record.id}/view`);
                }}
                icon={<EyeOutlined />}
              >
                {t("Common-View")}
              </Menu.Item>

              {hasPermission(privilegesEnum.WRITE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      showEditStatusModal(record);
                    }}
                    icon={<SendOutlined />}
                  >
                    {t("Common-Edit")}
                  </Menu.Item>
                )}
              {hasPermission(privilegesEnum.DELETE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      showDeleteConfirm(record.id);
                    }}
                    icon={<DeleteOutlined />}
                  >
                    {t("Common-Delete")}
                  </Menu.Item>
                )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.actionButtonMore}
          >
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Requests")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_REPOSITION_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    placeholder={t(
                      "Common-SearchBranchOfficeOriginTextPlaceholder"
                    )}
                    onSelect={(_, option) => {
                      if (option) {
                        handleFetchData(1, option.value);
                        setActualPage(1);
                        setActualBranchOfficeId(option.value);
                      }
                    }}
                    onClear={() => {
                      handleFetchData(1, undefined);
                      setActualPage(1);
                      setActualBranchOfficeId(undefined);
                    }}
                    notFoundContent={
                      loadingBranchOffices ? <Spin size="small" /> : null
                    }
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    allowClear
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchBranchOffices}
                    options={(branchOfficeOptions || []).map((d: any) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loadingArticles}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              rowClassName={getRowClassName}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Requests;
