import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteClientSegment,
  getClientSegmentsFiltered,
  getClientSegmentsCount,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Button,
  message,
  Modal,
  Input,
  Tag,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ClientSegments.module.scss";
import moment from "moment/moment";

const ClientSegments = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [descriptionFilterValue, setDescriptionFilterValue] = useState("");
  const [minMembersFilterValue, setMinMembersFilterValue] = useState<number | null>();
  const [maxMembersFilterValue, setMaxMembersFilterValue] = useState<number | null>();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClientSegments();
    }
  }, [
    nameFilterValue,
    descriptionFilterValue,
    minMembersFilterValue,
    maxMembersFilterValue,
  ]);

  const handleFetchClientSegments = async (pagination = actualPage) => {
    const response = await getClientSegmentsFiltered(pagination, {
      name: nameFilterValue,
      description: descriptionFilterValue,
      minMembers: minMembersFilterValue ?? undefined,
      maxMembers: maxMembersFilterValue ?? undefined,
    });
    setTotalSize(response.totalElements);

    const ids = response.clientSegments.map((segment: any) => segment.id);
    const counts = await getClientSegmentsCount(ids);
    const updatedClientSegments = response.clientSegments.map(
      (segment: any) => {
        const countData = counts.data.find(
          (data: any) => data.id === segment.id
        );
        return { ...segment, count: countData ? countData.count : 0 };
      }
    );
    setData(updatedClientSegments);

    setLoading(false);
  };

  const showDeleteConfirm = (clientSegmentId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSegment(clientSegmentId);
      },
    });
  };

  const handleDeleteSegment = async (clientSegmentId: number) => {
    setLoading(true);
    const deleteStatus = await deleteClientSegment(clientSegmentId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchClientSegments();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchClientSegments(page - 1);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Type"),
      key: "type",
      render: (segment: any) => segment.type.description_es,
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "count",
    },
    {
      title: t("Common-TicketAverage"),
      dataIndex: "averageTicket",
    },
    {
      title: t("Common-TotalSpent"),
      dataIndex: "totalSpent",
    },
    {
      title: t("Common-Status"),
      dataIndex: "active",
      render: (enabled: boolean) => (
        <Tag color={enabled ? "green" : "red"}>
          {enabled ? t("Common-Active") : t("Common-Inactive")}
        </Tag>
      ),
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-Origin"),
      dataIndex: "origin",
    },
    {
      title: t("Common-Analysis"),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, segment: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={
                  hasPermission(privilegesEnum.WRITE_CLIENTS) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() =>
                  (window.location.href = `/promotions/clientsegments/${segment.id}/view`)
                }
              >
                {t(
                  hasPermission(privilegesEnum.WRITE_CLIENTS)
                    ? "Common-Edit"
                    : "Common-View"
                )}
              </Menu.Item>
              {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
                <Menu.Item
                  key="delete"
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteConfirm(segment.id)}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];


  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientSegments")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              href="/promotions/clientsegments/create"
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setNameFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-Description")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setDescriptionFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={descriptionFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <InputNumber
                  min={0}
                  placeholder={t("Common-QuantityFrom")}
                  className={styles.filterInputNumber}
                  onChange={(value: number | null) => {
                    setMinMembersFilterValue(value);
                    setActualPage(1);
                  }}
                />
                <InputNumber
                  min={0}
                  placeholder={t("Common-QuantityTo")}
                  className={styles.filterInputNumber}
                  onChange={(value: number | null) => {
                    setMaxMembersFilterValue(value);
                    setActualPage(1);
                  }}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSegments;
