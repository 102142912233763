import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Table, Input, Select, Space, Tag, Modal, message, Button, Dropdown, Menu } from "antd";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { deleteRewardType, getRewardTypes } from "../../../api/Services";

import styles from "./RewardTypes.module.scss";

const RewardTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [activeFilterValue, setActiveFilterValue] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_VEHICLES)) fetchRewardTypes();
  }, [nameFilterValue, activeFilterValue]);

  const fetchRewardTypes = async (pagination = actualPage) => {
    const response = await getRewardTypes(pagination, {
      name: nameFilterValue,
      active: activeFilterValue,
    });

    setData(response.rewardTypes);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchRewardTypes(page);
    setActualPage(page);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteRewardType(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchRewardTypes();
    setLoading(false);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Status"),
      dataIndex: "status",
      render: (status: boolean) => (
        <Tag color={status ? "green" : "red"}>
          {status ? t("Common-Active") : t("Common-Inactive")}
        </Tag>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
  overlay={
    <Menu>
      <Menu.Item
        key="edit"
        icon={
          hasPermission(privilegesEnum.WRITE_REWARDS) ? (
            <EditOutlined />
          ) : (
            <EyeOutlined />
          )
        }
        onClick={() =>
          navigate(`/fidelity/rewardtypes/${record.id}/view`)
        }
      >
        {t(
          hasPermission(privilegesEnum.WRITE_REWARDS)
            ? "Common-Edit"
            : "Common-View"
        )}
      </Menu.Item>
      {hasPermission(privilegesEnum.DELETE_REWARDS) && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(record.id)}
        >
          {t("Common-Delete")}
        </Menu.Item>
      )}
    </Menu>
  }
>
  <Button className={styles.actionButtonMore}>
    <MoreOutlined className={styles.actionIconMore} />
  </Button>
</Dropdown>
      ),
    },
  ];
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-RewardTypes")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            href="/fidelity/rewardtypes/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setNameFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  defaultValue={activeFilterValue}
                  className={styles.filterSelect}
                  placeholder={t("Common-SearchByState")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) {
                      setActiveFilterValue(option.value);
                      setActualPage(1);
                    }
                  }}
                  onClear={() => {
                    setActiveFilterValue(undefined);
                    setActualPage(1);
                  }}
                >
                  <Select.Option value={0}>
                    {t("Common-Inactive")}
                  </Select.Option>
                  <Select.Option value={1}>{t("Common-Active")}</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            rowKey={(record) => record.id}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
              current: actualPage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardTypes;
