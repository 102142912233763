import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Table, Button, Input, Space, Modal, message, Tag, Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  deleteArticleGroup,
  getArticleGroups,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import styles from "./ArticleGroups.module.scss";

const ArticleGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  // const [loadingArticles, setLoadingArticles] = useState(false);
  // const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [nameFilterValue, setNameFilterValue] = useState("");
  // const [articleIdFilterValue, setArticleIdFilterValue] = useState("");

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ARTICLE_GROUP)) fetchArticleGroups();
  }, [nameFilterValue]);

  // const handleSearchArticles = async (value: string) => {
  //   setLoadingArticles(true);
  //   const response = await getArticlesFiltered(0, { articleName: value });
  //   const options = response.articles.map((item: any) => ({
  //     value: item.id,
  //     text: item.name,
  //   }));
  //   setArticleOptions(options);
  //   setLoadingArticles(false);
  // };

  const fetchArticleGroups = async (pagination = actualPage) => {
    const response = await getArticleGroups(pagination, {
      name: nameFilterValue,
    });

    setData(response.articleGroups);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteArticleGroup(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchArticleGroups();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    // handleFetchTickets(page);
    setActualPage(page);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: string) => date?.split("T")[0],
    },
    {
      title: t("Common-Status"),
      dataIndex: "state",
      render: (enable: boolean) => (
        <Tag color={enable ? "green" : "red"}>
          {enable ? t("Common-Active") : t("Common-Inactive")}
        </Tag>
      ),
    },
    {
      title: t("Common-Origin"),
      key: "origin",
      render: (record: any) => record.originInfo?.description_es,
    },
    {
      title: t("Common-Analysis"),
      dataIndex: "analysis",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={
                  hasPermission(privilegesEnum.WRITE_ARTICLE_GROUP) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() =>
                  navigate(`/pricings/articlegroups/${record.id}/view`)
                }
              >
                {hasPermission(privilegesEnum.WRITE_ARTICLE_GROUP)
                  ? t("Common-Edit")
                  : t("Common-View")}
              </Menu.Item>
              {hasPermission(privilegesEnum.DELETE_ARTICLE_GROUP) && (
                <Menu.Item
                  key="delete"
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteConfirm(record.id)}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ArticleGroups")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            href="/pricings/articlegroups/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
          <Button
            // onClick={handleCancelButton}
            className={styles.importButton}
            type="primary"
          >
            {t("Common-Import")}
          </Button>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setNameFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={nameFilterValue}
                />
              </div>
              {/*<div className={styles.filterWrapper}>*/}
              {/*  <Select*/}
              {/*    showSearch*/}
              {/*    allowClear*/}
              {/*    placeholder={t("Common-SearchArticleTextPlaceholder")}*/}
              {/*    loading={loadingArticles}*/}
              {/*    className={styles.filterSelect}*/}
              {/*    showArrow={false}*/}
              {/*    filterOption={false}*/}
              {/*    onSelect={(_, option) => {*/}
              {/*      if (option) {*/}
              {/*        setArticleIdFilterValue(option.value);*/}
              {/*      }*/}
              {/*    }}*/}
              {/*    onClear={() => {*/}
              {/*      setArticleIdFilterValue("");*/}
              {/*    }}*/}
              {/*    onSearch={handleSearchArticles}*/}
              {/*    options={(articleOptions || []).map((d: any) => ({*/}
              {/*      value: d.value,*/}
              {/*      label: d.text,*/}
              {/*    }))}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleGroups;
