import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  getUsers,
  deleteUser,
  getAllRoles,
  editUser,
  getBranchOfficesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Input,
  Button,
  message,
  Tag,
  Space,
  Modal,
  Select,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import { useNavigate } from "react-router-dom";
import styles from "./Users.module.scss";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<any[]>([]);
  const [emailFilterValue, setEmailFilterValue] = useState("");
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [rolFilterValue, setRolFilterValue] = useState<number>();
  const [branchOfficeFilterValue, setBranchOfficeFilterValue] =
    useState<number>();
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_USERS)) {
      handleFetchRoles();
      handleSearchBranchOffices("");
    }
  }, [businessUnitSelected]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_USERS)) handleFetchUsers();
  }, [
    emailFilterValue,
    rolFilterValue,
    branchOfficeFilterValue,
    nameFilterValue,
    businessUnitSelected,
  ]);

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleFetchUsers = useCallback(async () => {
    const allUsers = await getUsers({
      email: emailFilterValue,
      name: nameFilterValue,
      rolId: rolFilterValue,
      branchOfficeId: branchOfficeFilterValue,
      businessUnitId: businessUnitSelected ?? undefined,
    });
    setData(allUsers);
    setLoading(false);
  }, [
    nameFilterValue,
    emailFilterValue,
    rolFilterValue,
    branchOfficeFilterValue,
    businessUnitSelected,
  ]);

  const handleFetchRoles: () => any = async () => {
    const roles = await getAllRoles();
    setRoles(roles);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const showDeleteConfirm = (userId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteUser(userId);
      },
    });
  };

  const handleDeleteUser = async (userId: number) => {
    setLoading(true);
    const deleteStatus = await deleteUser(userId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchUsers();
    setLoading(false);
  };

  function showEditModal(user: any) {
    Modal.info({
      title: t("Users-Edit"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      onOk: handleEditUserRoles,
      content: (
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          form={editForm}
          autoComplete="off"
          className={styles.formEditRol}
          preserve={false}
        >
          <Form.Item hidden initialValue={user.id} name="id" />

          <Form.Item
            label={t("Common-Name")}
            name="name"
            initialValue={user?.name}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>

          <Form.Item
            label={t("Common-LastName")}
            name="lastName"
            initialValue={user?.lastName}
          >
            <Input placeholder={t("Common-LastName")} />
          </Form.Item>

          <Form.Item
            initialValue={user?.roles?.map((rol: any) => rol.id)}
            label={t("Common-Roles")}
            name="roles"
            rules={[
              {
                required: true,
                message: t("Users-Rol"),
              },
            ]}
          >
            <Select
              className={styles.privilegesSelector}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={user?.roles?.map((rol: any) => rol.id)}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(roles)}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("Common-BranchOffices")}
            name="branchOfficesIds"
            className={styles.doubleItemLine}
            initialValue={user?.branchOffices?.map(
              (branchOffice: any) => branchOffice.id
            )}
          >
            <Select
              mode="multiple"
              allowClear
              className={styles.branchOfficeSelector}
              onSearch={handleSearchBranchOffices}
              placeholder={t("Common-SearchByName")}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(branchOffices)}
            </Select>
          </Form.Item>
        </Form>
      ),
    });
  }

  const handleEditUserRoles = async () => {
    await editForm.validateFields().then(async (data) => {
      setLoading(true);

      const { id, roles, branchOfficesIds, name, lastName } = data;

      let response = await editUser(
        id,
        name,
        lastName,
        roles,
        [businessUnitSelected],
        branchOfficesIds
      );

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      Modal.destroyAll();
      await handleFetchUsers();
      setLoading(false);
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-LastName"),
      dataIndex: "lastName",
    },
    {
      title: t("Common-Email"),
      dataIndex: "email",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: t("Common-Status"),
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: boolean) => (
        <>
          <Tag color={enabled ? "green" : "red"}>
            {enabled ? t("Common-Active") : t("Common-Inactive")}
          </Tag>
        </>
      ),
    },
    {
      title: t("Roles-Rol"),
      dataIndex: "roles",
      key: "roles",
      render: (roles: any[]) => (
        <>
          {roles.map((rol) => {
            return (
              <Tag className={styles.rolTag} color="blue" key={rol.id}>
                {rol.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-BranchOffices"),
      dataIndex: "branchOffices",
      render: (branchOffices: any[]) => (
        <>
          {branchOffices?.map((branchOffice) => {
            return (
              <Tag className={styles.tag} color="blue" key={branchOffice.id}>
                {branchOffice.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_USERS) && (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    showEditModal(record);
                  }}
                  icon={<EditOutlined />}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              )}
              {record.roles.find((rol: any) => rol.name === "Admin") === undefined &&
                hasPermission(privilegesEnum.DELETE_USERS) && (
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      showDeleteConfirm(record.id);
                    }}
                    icon={<DeleteOutlined />}
                  >
                    {t("Common-Delete")}
                  </Menu.Item>
                )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Users-UsersSettings")}</h1>
        {hasPermission(privilegesEnum.WRITE_CATEGORIES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/admin/users/create")}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_USERS) && (
          <div className={styles.tableUsersWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => setNameFilterValue(e.target.value)}
                    value={nameFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByEmail")}
                    className={styles.filterInput}
                    onChange={(e) => setEmailFilterValue(e.target.value)}
                    value={emailFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    className={styles.filterSelect}
                    placeholder={t("Common-SearchByRol")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) setRolFilterValue(option.value);
                    }}
                    onClear={() => setRolFilterValue(undefined)}
                  >
                    {getOptions(roles)}
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    className={styles.filterSelect}
                    onSearch={handleSearchBranchOffices}
                    placeholder={t("Common-SearchByBranchOffice")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) setBranchOfficeFilterValue(option.value);
                    }}
                    onClear={() => setBranchOfficeFilterValue(undefined)}
                  >
                    {getOptions(branchOffices)}
                  </Select>
                </div>
              </div>
            </div>

            <Table
              size="small"
              className={styles.tableUsers}
              loading={loading}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
