import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteClientLevel, getClientLevels } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal, Input, Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ClientLevels.module.scss";
import { useNavigate } from "react-router-dom";

const ClientLevels = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [levelFilterValue, setLevelFilterValue] = useState("");

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENT_LEVEL)) fetchClientLevels();
  }, [nameFilterValue, levelFilterValue]);

  const fetchClientLevels = async (pagination = actualPage) => {
    const response = await getClientLevels(pagination, {
      name: nameFilterValue,
      level: levelFilterValue,
    });

    setData(response.clientLevels);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteClientLevel(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchClientLevels();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchClientLevels(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Level"),
      dataIndex: "level",
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-TotalClients"),
      dataIndex: "totalClients",
    },
    {
      title: t("Common-AccumulationFactor"),
      dataIndex: "accumulationFactor",
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: string) => date?.split("T")[0],
    },
    {
      title: t("Common-Origin"),
      dataIndex: "origin",
    },
    {
      title: t("Common-Analysis"),
      dataIndex: "analysis",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={
                  hasPermission(privilegesEnum.WRITE_CLIENT_LEVEL) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() =>
                  navigate(`/promotions/clientlevels/${record.id}/view`)
                }
              >
                {t(
                  hasPermission(privilegesEnum.WRITE_CLIENT_LEVEL)
                    ? "Common-Edit"
                    : "Common-View"
                )}
              </Menu.Item>
              {hasPermission(privilegesEnum.DELETE_CLIENT_LEVEL) && (
                <Menu.Item
                  key="delete"
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteConfirm(record.id)}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientLevels")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENT_LEVEL) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/promotions/clientlevels/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_CLIENT_LEVEL) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setNameFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={nameFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByLevel")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setLevelFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={levelFilterValue}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientLevels;
