import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteCluster, getClusters } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal, Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Clusters.module.scss";
import { useNavigate } from "react-router-dom";

const Clusters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLUSTERS)) fetchClusters();
  }, []);

  const fetchClusters = async (pagination = actualPage) => {
    const response = await getClusters(pagination);

    setData(response.clusters);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteCluster(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchClusters();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchClusters(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={
                  hasPermission(privilegesEnum.WRITE_CLUSTERS) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() => navigate(`/admin/clusters/${record.id}/view`)}
              >
                {t(
                  hasPermission(privilegesEnum.WRITE_CLUSTERS)
                    ? "Common-Edit"
                    : "Common-View"
                )}
              </Menu.Item>
              {hasPermission(privilegesEnum.DELETE_CLUSTERS) && (
                <Menu.Item
                  key="delete"
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteConfirm(record.id)}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Clusters")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLUSTERS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/admin/clusters/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_CLUSTERS) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clusters;
