import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  deleteArticle,
  getArticlesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
  processExcelTemplate,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import ImportModal from "../../components/importmodal/ImportModal";
import { handleDownloadTemplate } from "../../helpers/Helper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import { Button, Dropdown, Input, Menu, message, Modal, Select, Table } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Articles.module.scss";

const Articles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [articles, setArticles] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");
  const [categoryIdFilterValue, setCategoryIdFilterValue] = useState<number>();
  const [brandIdFilterValue, setBrandIdFilterValue] = useState<number>();

  const canEdit = hasPermission(privilegesEnum.WRITE_ARTICLES);
  const canDelete = hasPermission(privilegesEnum.DELETE_ARTICLES);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ARTICLES)) {
      handleFetchArticles(1);
      setActualPage(1);
    }
  }, [
    nameFilterValue,
    externalIdFilterValue,
    businessUnitSelected,
    categoryIdFilterValue,
    brandIdFilterValue,
  ]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ARTICLES)) {
      handleSearchBrands("");
      handleSearchCategories("");
    }
  }, []);

  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleFetchArticles = async (pagination = actualPage) => {
    setLoading(true);
    if (businessUnitSelected) {
      const response = await getArticlesFiltered(
        pagination - 1,
        {
          articleName: nameFilterValue,
          externalId: externalIdFilterValue,
          categoryId: categoryIdFilterValue?.toString(),
          brandId: brandIdFilterValue,
          businessUnitId: businessUnitSelected,
        },
        false,
        "ArticleProjectionWithoutStock"
      );
      setLoading(false);
      const allArticles = response.articles;
      setArticles(allArticles);
      setTotalSize(response.totalElements);
    }
  };

  const handlePagination = (page: number) => {
    handleFetchArticles(page);
    setActualPage(page);
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("articles", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchArticles();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const showDeleteConfirm = (articleId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteArticle(articleId);
      },
    });
  };

  const handleDeleteArticle = async (articleId: number) => {
    setLoading(true);
    const deleteStatus = await deleteArticle(articleId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticles();
    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      size: 20,
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
      size: 10,
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
      size: 30,
    },
    {
      title: t("Common-Category"),
      dataIndex: "category",
      key: "category",
      size: 20,
      render: (category: any) => {
        const parentsString = category?.parents
          ? category.parents.filter(Boolean).join(" - ")
          : "";
        const categoryName = category?.name || "";
  
        return parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;
      },
    },
    {
      title: t("Common-Brand"),
      dataIndex: "brand",
      key: "brand",
      size: 20,
      render: (brand: any) => brand?.name,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      size: 15,
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={canEdit ? <EditOutlined /> : <EyeOutlined />}  
                onClick={() => {
                navigate(`/admin/articles/${record.id}/view`);
              }}
            >
              {t(canEdit ? "Common-Edit" : "Common-View")}
            </Menu.Item>
            {canDelete && (
              <Menu.Item
                key="delete"
                icon={<DeleteOutlined />}
                  onClick={() => {
                    showDeleteConfirm(record.id);
                  }}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Articles-Articles")}</h1>
        {hasPermission(privilegesEnum.WRITE_ARTICLES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/admin/articles/create")}
              className={styles.newArticleButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              onClick={() => {
                setImportErrors("");
                setIsImportModalVisible(true);
              }}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate("articles", setDownloadingTemplate)
              }
              loading={downloadingTemplate}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_ARTICLES) && (
          <div className={styles.tableArticlesWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(event) => setNameFilterValue(event.target.value)}
                  ></Input>
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByExternalId")}
                    className={styles.filterInput}
                    onChange={(event) =>
                      setExternalIdFilterValue(event.target.value)
                    }
                  ></Input>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    showSearch
                    className={styles.filterSelect}
                    onSearch={handleSearchCategories}
                    placeholder={t("Common-SearchByCategory")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) setCategoryIdFilterValue(option.value);
                    }}
                    onClear={() => setCategoryIdFilterValue(undefined)}
                  >
                    {getOptions(categories)}
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    showSearch
                    className={styles.filterSelect}
                    onSearch={handleSearchBrands}
                    placeholder={t("Common-SearchByBrand")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) setBrandIdFilterValue(option.value);
                    }}
                    onClear={() => setBrandIdFilterValue(undefined)}
                  >
                    {getOptions(brands)}
                  </Select>
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.tableArticles}
              rowKey={(record) => record.id}
              loading={loading}
              columns={columns}
              dataSource={articles}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", { entity: t("Common-Articles") })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsImportModalVisible(false)}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default Articles;
