import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  getAllSuppliers,
  getPurchaseOrdersByOrganization,
  deletePurchaseOrder,
  markReceivedOrder,
  editPurchaseOrderStatus,
} from "../../api/Services";
import { purchaseOrderStatus } from "../../helpers/Constants";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  message,
  Modal,
  Button,
  Select,
  Form,
  Input,
  Spin,
  DatePicker,
  InputNumber,
  Checkbox,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
  MoreOutlined,
  RedoOutlined,
  SendOutlined,
} from "@ant-design/icons";

import styles from "./PurchaseOrders.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [actualFilterSupplierId, setActualFilterSupplierId] = useState<
    number | null
  >(null);
  const [actualFilterStatus, setActualFilterStatus] = useState<any>(null);
  const [actualFilterStartDate, setActualFilterStartDate] = useState<any>(null);
  const [actualFilterEndDate, setActualFilterEndDate] = useState<any>(null);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState(0);

  const [receivedForm] = Form.useForm();
  const [statusForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PURCHASE_ORDERS)) {
      handleFetchPurchaseOrder();
      handleFetchSuppliers();
    }
  }, []);

  const handleFetchPurchaseOrder = async (
    pagination = actualPage,
    supplierId?: number | null,
    status?: string | null,
    startDate?: Date | null,
    endDate?: Date | null
  ) => {
    setLoading(true);
    const response = await getPurchaseOrdersByOrganization(
      pagination - 1,
      supplierId,
      status,
      null,
      startDate ? moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null,
      endDate ? moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null
    );
    const allOrders = response.purchaseOrders;
    const ordersWithKey: any[] = [];

    allOrders.forEach((order: any) => {
      ordersWithKey.push({ ...order, key: order.id });
    });
    setData(ordersWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handleFetchSuppliers = async () => {
    const response = await getAllSuppliers();
    setSuppliers(response);
    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handlePagination = (page: number) => {
    handleFetchPurchaseOrder(page);
    setActualPage(page);
  };

  const showDeleteConfirm = (purchaseOrderId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeletePurhcaseOrder(purchaseOrderId);
      },
    });
  };

  const handleDeletePurhcaseOrder = async (purchaseOrderId: number) => {
    setLoading(true);
    const deleteStatus = await deletePurchaseOrder(purchaseOrderId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchPurchaseOrder();
    setLoading(false);
  };

  const handleChangeStatusFilter = (value: any) => {
    setActualFilterStatus(value);
    handleFetchPurchaseOrder(
      actualPage,
      actualFilterSupplierId,
      value,
      actualFilterStartDate,
      actualFilterEndDate
    );
  };

  const handleChangeSupplierFilter = (value: any) => {
    setActualFilterSupplierId(value);
    handleFetchPurchaseOrder(
      actualPage,
      value,
      actualFilterStatus,
      actualFilterStartDate,
      actualFilterEndDate
    );
  };

  const handleChangeDateFilter = (value: any) => {
    setActualFilterStartDate(value ? value[0] ?? null : null);
    setActualFilterEndDate(value ? value[1] ?? null : null);
    handleFetchPurchaseOrder(
      actualPage,
      actualFilterSupplierId,
      actualFilterStatus,
      value ? value[0] ?? null : null,
      value ? value[1] ?? null : null
    );
  };

  const handleReceived = async () => {
    const receivedValues = receivedForm.getFieldsValue();
    const receivedValuesArray = Object.values(receivedValues);
    const receivedValuesBody: any = [];
    receivedValuesArray.forEach((value: any) => {
      value.date = value.date.format("YYYY-MM-DD");
      value.quantity = parseInt(value.quantity);
      receivedValuesBody.push(value);
    });
    const response = await markReceivedOrder(receivedValuesBody);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      handleFetchPurchaseOrder(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const handleChangeStatus = async () => {
    const { id, status, notCompletedReason } = statusForm.getFieldsValue();
    const response = await editPurchaseOrderStatus(
      id,
      status,
      notCompletedReason
    );
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      handleFetchPurchaseOrder(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const showReceivedModal = (order: any) => {
    Modal.info({
      title: t("Common-Reception"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={receivedForm}
          // onFinish={handleEditCategory}
          preserve={false}
        >
          {order.skus.map((sku: any) => {
            return (
              <div key={sku.id}>
                <Form.Item hidden initialValue={sku.id} name={[sku.id, "id"]} />

                <p>{sku.skuName}</p>
                <Form.Item
                  className={styles.editItem}
                  label={t("Common-Quantity")}
                  name={[sku.id, "quantity"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-Quantity"),
                    },
                  ]}
                >
                  <InputNumber placeholder={t("Common-Quantity")} />
                </Form.Item>
                {/* commented ticket #52*/}
                {/*<Form.Item*/}
                {/*  className={styles.editItem}*/}
                {/*  label={t("Common-WhoReceived")}*/}
                {/*  name={[sku.id, "whoReceived"]}*/}
                {/*  rules={[*/}
                {/*    {*/}
                {/*      required: true,*/}
                {/*      message: t("Common-WhoReceived"),*/}
                {/*    },*/}
                {/*  ]}*/}
                {/*>*/}
                {/*  <Input placeholder={t("Common-WhoReceived")} />*/}
                {/*</Form.Item>*/}
                <Form.Item
                  className={styles.editItem}
                  label={t("Common-Observation")}
                  name={[sku.id, "observations"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-Observation"),
                    },
                  ]}
                >
                  <Input placeholder={t("Common-Observation")} />
                </Form.Item>

                <Form.Item
                  className={styles.newItem}
                  label={t("Common-Date")}
                  name={[sku.id, "date"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-Date"),
                    },
                  ]}
                >
                  <DatePicker
                    className={styles.dateSelector}
                    placeholder={t("Common-Date")}
                    // onChange={handleChangeDate}
                  />
                </Form.Item>
              </div>
            );
          })}
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleReceived}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const hasReceived = (order: any) => {
    let hasReceived = false;
    order.skus?.forEach((sku: any) => {
      if (sku.received.length > 0) hasReceived = true;
    });
    return hasReceived;
  };

  const showEditStatusModal = (order: any) => {
    Modal.info({
      title: t("Common-Status"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={statusForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={order.id} name="id" />
          <Form.Item
            label={t("Common-Status")}
            name="status"
            initialValue={order.orderStatus.status}
            rules={[
              {
                required: true,
                message: t("Common-Status"),
              },
            ]}
          >
            <Select
              className={styles.filterSelect}
              allowClear
              defaultValue={order.orderStatus.status}
            >
              {order.orderStatus.status === purchaseOrderStatus.PARTIAL && (
                <Select.Option
                  value={purchaseOrderStatus.PARTIAL}
                  key={purchaseOrderStatus.PARTIAL}
                  disabled={true}
                >
                  {t("Common-Partial")}
                </Select.Option>
              )}
              <Select.Option
                value={purchaseOrderStatus.SUGGESTED}
                key={purchaseOrderStatus.SUGGESTED}
              >
                {t("Common-Suggested")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.GENERATED}
                key={purchaseOrderStatus.GENERATED}
              >
                {t("Common-Generated")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.SENT}
                key={purchaseOrderStatus.SENT}
              >
                {t("Common-Sent")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.IN_PROGRESS}
                key={purchaseOrderStatus.IN_PROGRESS}
              >
                {t("Common-InProgress")}
              </Select.Option>
              {hasReceived(order) && (
                <Select.Option
                  value={purchaseOrderStatus.COMPLETED}
                  key={purchaseOrderStatus.COMPLETED}
                >
                  {t("Common-Completed")}
                </Select.Option>
              )}
              <Select.Option
                value={purchaseOrderStatus.NOT_COMPLETED}
                key={purchaseOrderStatus.NOT_COMPLETED}
              >
                {t("Common-NotCompleted")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.status !== currentValues.status
            }
          >
            {({ getFieldValue }) => {
              switch (getFieldValue("status")) {
                case "NOT_COMPLETED":
                  return (
                    <Form.Item
                      label={t("Common-Reason")}
                      name="notCompletedReason"
                      className={styles.doubleItemLine}
                      initialValue={order.notCompletedReason}
                      rules={[
                        {
                          message: t("Common-Reason"),
                        },
                      ]}
                    >
                      <Input placeholder={t("Common-Reason")} />
                    </Form.Item>
                  );
              }
            }}
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleChangeStatus}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const getRowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? styles.rowLight : styles.rowDark;
  };

  const columns = [
    {
      title: t("Common-Supplier"),
      dataIndex: "supplier",
      key: "supplier",
      render: (supplier: any) => (
        <a href={`/admin/suppliers/${supplier.id}/view`}>{supplier?.name}</a>
      ),
    },
    {
      title: t("Common-Destination"),
      key: "destiny",
      render: (text: any, record: any) => {
        const isDeposit = record.deposit !== null;
        const id = isDeposit ? record.deposit.id : record.branchOffice.id;
        const name = isDeposit ? record.deposit.name : record.branchOffice.name;

        return (
          <a
            href={`/${
              isDeposit ? "deposits" : "businessunit/branchoffice"
            }/${id}/view`}
          >
            {name}
          </a>
        );
      },
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdDate",
      sorter: (a: any, b: any) => a.createdDate - b.createdDate,
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-EstimatedDeliveryDate"),
      dataIndex: "estimatedDeliveryDate",
      key: "estimatedDeliveryDate",
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-Status"),
      dataIndex: "orderStatus",
      key: "status",
      render: (status: any) => (
        <span className={styles.statusWrapper}>{status.description_es}</span>
      ),
    },
    {
      title: t("Common-Mode"),
      dataIndex: "orderOrigin",
      key: "orderOrigin",
      render: (orderOrigin: any) => orderOrigin?.description_es,
    },
    {
      title: t("Common-Analysis"),
      dataIndex: "analysis",
      key: "analysis",
    },
    {
      title: t("Common-Complete"),
      key: "analysis",
      render: (record: any) => (
        <Checkbox
          className={styles.completedCheckbox}
          checked={record.orderStatus.status === purchaseOrderStatus.COMPLETED}
          onClick={() => {
            if (record.orderStatus.status !== purchaseOrderStatus.COMPLETED)
              showReceivedModal(record);
          }}
        />
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/purchaseorders/${record.id}/view`);
                }}
                icon={<EyeOutlined />}
              >
                {t("Common-View")}
              </Menu.Item>

              {hasPermission(privilegesEnum.WRITE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      showEditStatusModal(record);
                    }}
                    icon={<SendOutlined />}
                  >
                    {t("Common-Edit")}
                  </Menu.Item>
                )}
              {hasPermission(privilegesEnum.DELETE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      showDeleteConfirm(record.id);
                    }}
                    icon={<DeleteOutlined />}
                  >
                    {t("Common-Delete")}
                  </Menu.Item>
                )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.actionButtonMore}
          >
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-PurchaseOrders")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PURCHASE_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelector}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    onChange={handleChangeDateFilter}
                    onCalendarChange={handleChangeDateFilter}
                    placeholder={[t("Common-Start"), t("Common-End")]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    placeholder={t("Common-Status")}
                    className={styles.filterSelect}
                    onChange={handleChangeStatusFilter}
                    allowClear
                  >
                    <Select.Option
                      value={purchaseOrderStatus.SUGGESTED}
                      key={purchaseOrderStatus.SUGGESTED}
                    >
                      {t("Common-Suggested")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.GENERATED}
                      key={purchaseOrderStatus.GENERATED}
                    >
                      {t("Common-Generated")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.IN_PROGRESS}
                      key={purchaseOrderStatus.IN_PROGRESS}
                    >
                      {t("Common-InProgress")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.COMPLETED}
                      key={purchaseOrderStatus.COMPLETED}
                    >
                      {t("Common-Completed")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.NOT_COMPLETED}
                      key={purchaseOrderStatus.NOT_COMPLETED}
                    >
                      {t("Common-NotCompleted")}
                    </Select.Option>
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    placeholder={t("Common-Supplier")}
                    className={styles.filterSelect}
                    onChange={handleChangeSupplierFilter}
                    allowClear
                  >
                    {getOptions(suppliers)}
                  </Select>
                </div>
              </div>
              <div>
                <Button
                  icon={<RedoOutlined />}
                  onClick={() =>
                    handleFetchPurchaseOrder(
                      actualPage,
                      actualFilterStatus,
                      actualFilterStartDate,
                      actualFilterEndDate
                    )
                  }
                />
                {hasPermission(privilegesEnum.WRITE_PURCHASE_ORDERS) && (
                  <Button
                    onClick={() => navigate("/purchaseorders/create")}
                    className={styles.newButton}
                    type="primary"
                  >
                    {t("Common-NewOrder")}
                  </Button>
                )}
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              rowClassName={getRowClassName}
              loading={loading}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseOrders;
