import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteClient,
  getClientLevels,
  getClients,
  getClientSegmentsFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Button,
  message,
  Modal,
  Dropdown,
  Menu,
  Input,
  Select,
  DatePicker,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Clients.module.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const Clients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [clientSegments, setClientSegments] = useState<any[]>([]);
  const [clientLevels, setClientLevels] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [idNumberFilterValue, setIdNumberFilterValue] = useState<string>("");
  const [segmentFilterValue, setSegmentFilterValue] = useState<string>("");
  const [levelFilterValue, setLevelFilterValue] = useState<string>("");
  const [actualFilterBirthStartDate, setActualFilterBirthStartDate] =
    useState<any>(null);
  const [actualFilterBirthdateEndDate, setActualFilterBirthdateEndDate] =
    useState<any>(null);
  const [actualFilterRegisterStartDate, setActualFilterRegisterStartDate] =
    useState<any>(null);
  const [actualFilterRegisterEndDate, setActualFilterRegisterEndDate] =
    useState<any>(null);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleSearchLevels("");
      handleSearchClientSegments("");
    }
  }, []);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClients();
    }
  }, [
    idNumberFilterValue,
    segmentFilterValue,
    levelFilterValue,
    actualFilterBirthdateEndDate,
    actualFilterBirthStartDate,
    actualFilterRegisterStartDate,
    actualFilterRegisterEndDate,
  ]);

  const handleFetchClients = async (pagination = actualPage) => {
    const response = await getClients(pagination, {
      idNumber: idNumberFilterValue,
      segmentId: segmentFilterValue,
      levelId: levelFilterValue,
      birthStartDate: actualFilterBirthStartDate
        ? moment(actualFilterBirthStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
      birthEndDate: actualFilterBirthdateEndDate
        ? moment(actualFilterBirthdateEndDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        : "",
      registerStartDate: actualFilterRegisterStartDate
        ? moment(actualFilterRegisterStartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        : "",
      registerEndDate: actualFilterRegisterEndDate
        ? moment(actualFilterRegisterEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
    });
    setData(response.clients);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleSearchClientSegments = async (value: string) => {
    const response = await getClientSegmentsFiltered(1, { name: value });
    setClientSegments(response.clientSegments);
  };

  const handleSearchLevels = async (value: string) => {
    const response = await getClientLevels(0, { name: value });
    setClientLevels(response.clientLevels);
  };

  const handleChangeBirthDateFilter = (value: any) => {
    setActualFilterBirthStartDate(value ? value[0] ?? null : null);
    setActualFilterBirthdateEndDate(value ? value[1] ?? null : null);
  };

  const handleChangeRegisterDateFilter = (value: any) => {
    setActualFilterRegisterStartDate(value ? value[0] ?? null : null);
    setActualFilterRegisterEndDate(value ? value[1] ?? null : null);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteClient(itemId);
      },
    });
  };

  const handleDeleteClient = async (clientId: number) => {
    setLoading(true);
    const deleteStatus = await deleteClient(clientId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchClients();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchClients(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Identifier"),
      dataIndex: "idNumber",
    },
    {
      title: t("Common-FidelityId"),
      dataIndex: "fidelity",
    },
    {
      title: t("Common-BirthDate"),
      dataIndex: "birthdate",
    },
    {
      title: t("Common-RegisterDate"),
      dataIndex: "createdAt",
      render: (date: string) => date?.split("T")[0],
    },
    {
      title: t("Common-Level"),
      key: "level",
    },
    {
      title: t("Common-Segments"),
      key: "segments",
    },
    {
      title: t("Common-TotalAccumulationUnits"),
      dataIndex: "accumulatedUnits",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/promotions/clients/${record.id}/view`);
                }}
                icon={
                  hasPermission(privilegesEnum.WRITE_CLIENTS) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
              >
                {hasPermission(privilegesEnum.WRITE_PRICINGS)
                  ? t("Common-Edit")
                  : t("Common-View")}
              </Menu.Item>

              {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    showDeleteConfirm(record.id);
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.actionButtonMore}
          >
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Clients")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/promotions/clients/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button className={styles.importButton} type="primary">
              {t("Common-DownloadCsv")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_CLIENTS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-Identifier")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setIdNumberFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={idNumberFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    className={styles.filterSelect}
                    showSearch
                    onSelect={(_, option) => {
                      if (option) setSegmentFilterValue(option.value);
                    }}
                    allowClear
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onClear={() => setSegmentFilterValue("")}
                    onSearch={handleSearchClientSegments}
                    placeholder={t("Common-ClientSegment")}
                  >
                    {getOptions(clientSegments)}
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    className={styles.filterSelect}
                    showSearch
                    onSelect={(_, option) => {
                      if (option) setLevelFilterValue(option.value);
                    }}
                    allowClear
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onClear={() => setLevelFilterValue("")}
                    onSearch={handleSearchLevels}
                    placeholder={t("Common-ClientLevel")}
                  >
                    {getOptions(clientLevels)}
                  </Select>
                </div>
              </div>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelector}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    onChange={handleChangeBirthDateFilter}
                    onCalendarChange={handleChangeBirthDateFilter}
                    placeholder={[
                      t("Common-StartDateBirthdate"),
                      t("Common-EndDateBirthdate"),
                    ]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelector}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    onChange={handleChangeRegisterDateFilter}
                    onCalendarChange={handleChangeRegisterDateFilter}
                    placeholder={[
                      t("Common-StartDateRegister"),
                      t("Common-EndDateRegister"),
                    ]}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
