import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  getAttributes,
  createAttribute,
  deleteAttribute,
  editAttribute,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Input,
  Button,
  message,
  Select,
  Tag,
  Space,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import styles from "./Attributes.module.scss";

const Attributes = () => {
  const { t } = useTranslation();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [attributeFilterValue, setAttributeFilterValue] = useState("");
  const [valueFilterValue, setValueFilterValue] = useState("");

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ATTRIBUTES)) {
      handleFetchAttributes();
    }
  }, [attributeFilterValue, valueFilterValue, businessUnitSelected]);

  const handleFetchAttributes = async (pagination = actualPage) => {
    if (businessUnitSelected) {
      const response = await getAttributes(pagination, {
        attribute: attributeFilterValue,
        value: valueFilterValue,
        businessUnitId: businessUnitSelected,
      });

      setData(response.attributes);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const showDeleteConfirm = (attributeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteAttribute(attributeId);
      },
    });
  };

  const handleDeleteAttribute = async (attributeId: number) => {
    setLoading(true);
    const deleteStatus = await deleteAttribute(attributeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchAttributes();
    setLoading(false);
  };

  const handleCreateAttribute = async (data: any) => {
    setLoading(true);
    if (businessUnitSelected) data.businessUnitsIds = [businessUnitSelected];
    let response = await createAttribute(
      data.name,
      data.businessUnitsIds,
      data.enums
    );
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      await handleFetchAttributes(actualPage);
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  function showEditModal(attribute: any) {
    Modal.info({
      title: t("Common-Attribute"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={attribute.id} name="id" />
            <Form.Item
              initialValue={attribute?.attribute}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("Common-PossibleValues")}
              initialValue={attribute?.enums.map((e: any) => e.value)}
              name="enums"
              className={styles.editInfoItem}
            >
              <Select
                allowClear
                placeholder={t("Common-EnterPossibleValues")}
                showSearch
                mode="tags"
                className={styles.selector}
                defaultValue={attribute?.enums.map((e: any) => e.value)}
              />
            </Form.Item>
          </Form>
          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEditAttribute}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  }

  const handleEditAttribute = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const name = editForm.getFieldValue("name");
    const businessUnitsIds = [businessUnitSelected];
    const enums = editForm.getFieldValue("enums");
    let response = await editAttribute(id, name, businessUnitsIds, enums);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchAttributes();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchAttributes(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Attribute"),
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: t("Common-PossibleValues"),
      dataIndex: "enums",
      key: "enums",
      render: (enums: any[]) => (
        <>
          {enums?.map((possibleEnum) => {
            return (
              <Tag className={styles.tag} color="blue" key={possibleEnum.id}>
                {possibleEnum.value}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_ATTRIBUTES) && (
                <Menu.Item
                  key="edit"
                  onClick={() => showEditModal(record)}
                  icon={<EditOutlined />}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              )}

              {hasPermission(privilegesEnum.DELETE_ATTRIBUTES) && (
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    showDeleteConfirm(record.id);
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.actionButtonMore}
          >
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Attributes")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_ATTRIBUTES) && (
          <div className={styles.tableAttributesWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByAttribute")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setAttributeFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={attributeFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByValue")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setValueFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={valueFilterValue}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.tableAttributes}
              loading={loading}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
        {hasPermission(privilegesEnum.WRITE_ATTRIBUTES) && (
          <div className={styles.newAttributeWrapper}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleCreateAttribute}
              form={form}
              layout="vertical"
              autoComplete="off"
              className={styles.formNewAttribute}
            >
              <h1 className={styles.formTitle}>{t("Common-NewAttribute")}</h1>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Name")} />
              </Form.Item>

              <Form.Item label={t("Common-PossibleValues")} name="enums">
                <Select
                  allowClear
                  placeholder={t("Common-EnterPossibleValues")}
                  showSearch
                  mode="tags"
                  className={styles.selector}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Common-Add")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Attributes;
